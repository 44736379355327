<template>
  <div id="news-details">
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col cols="12">
          <news-form-edit :newsingle="newsingle"></news-form-edit>
        </b-col>
      </b-row>
    </b-card>
    
  </div>
</template>

<script>
  import NewsFormEdit from './components/NewsFormEdit'

  export default{
    name: 'NewsDetails',
    components: {NewsFormEdit},
    props: ['newsingle'],
    data() {
      return {
      }
    },
  }
</script>