<template>
  <div>
    <b-form>
      <!-- -->

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Tags Noticia" label-for="tags">
            <v-select v-model="editForm.tagsNews" label="name" name="tagsNews" multiple placeholder="Seleccione"
              :options="dataTagsNews" :reduce="dataTagsNews => dataTagsNews.id">
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group label="Proyectos Noticia" label-for="projectsNews">
            <v-select v-model="editForm.projectsNews" label="name" name="projectsNews" multiple placeholder="Seleccione"
              :options="projects" :reduce="projects => projects.id">
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col xl="12" sm="12">
          <div class="divInfo" style="background-color: rgb(239, 239, 239);">
            <p class="flex">
              <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />Resolución portada: 1366x768
            </p>
          </div>
          <b-form-group label="Imagen Header" label-for="logo">
            <b-form-file id="logo" type="file" browse-text="Formatos: jpeg - jpg" accept=".jpg, .svg"
              @change="_previewLogo" placeholder="Selecciona una imagen" />

          </b-form-group>
          <b-row class="mt-2" v-if="editForm.media_logo != undefined">
            <b-col v-if="editForm.media_logo.full_url === ''" cols="12">
              <div class="preview">

                <!-- <h5><strong>1366x768</strong></h5> -->
              </div>
            </b-col>
            <b-col v-else cols="12">
              <div class="container" width="300">
                <img class="image" :src="editForm.media_logo.full_url" style="max-width:300px">
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col xl="6" sm="12">
          <b-form-group label="Titulo" label-for="title">
            <b-form-input id="name" v-model="editForm.title" name="name" :state="titleErrors.length > 0 ? false : null"
              @input="$v.editForm.title.$touch()" />
            <small class="text-danger">{{ titleErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col xl="6" sm="12">
          <b-form-group label="Subtitulo" label-for="subtitle">
            <b-form-input id="subtitle" v-model="editForm.subtitle" name="subtitle"
              @input="$v.editForm.subtitle.$touch()" />

          </b-form-group>
        </b-col>

        <b-col cols="12" class="margin-bottom-20">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Cuerpo Header</p>
            <ckeditor id="editor1" :editor="editor" v-model="editForm.body" :config="editorConfig"
              :state="bodyErrors.length > 0 ? false : null" @input="$v.editForm.body.$touch()"></ckeditor>
            <small class="text-danger">{{ bodyErrors[0] }}</small>
          </div>
        </b-col>
        <b-col xl="12" sm="12">
          <div class="divInfo" style="background-color: rgb(239, 239, 239);">
            <p class="flex">
              <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />Resolución portada: 1024x768
            </p>
          </div>
          <b-form-group label="Imagen cuerpo" label-for="Imagen cuerpo">
            <b-form-file id="cover_page" type="file" browse-text="Formatos: jpeg - jpg - png" accept=".jpg, .png, .svg"
              placeholder="Selecciona una imagen" @change="_previewPortada" />
          </b-form-group>
          <b-row class="mt-2" v-if="editForm.media_coverpage != undefined">
            <b-col v-if="editForm.media_coverpage.full_url === ''" cols="12">
              <div class="preview">
                <!-- <h5><strong>1366x768</strong></h5> -->
              </div>
            </b-col>
            <b-col v-else cols="12">
              <div class="container" width="300">
                <img class="image" :src="editForm.media_coverpage.full_url" style="max-width:300px">
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" class="margin-bottom-20">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Cuerpo footer</p>
            <ckeditor id="editor2" :editor="editor" v-model="editForm.body_footer" :config="editorConfig"
              @input="$v.editForm.body_footer.$touch()"></ckeditor>

          </div>
        </b-col>


        <b-col xl="6" sm="12">
          <b-form-group label="Fuente" label-for="fuente">
            <b-form-input id="fuente" v-model="editForm.source" name="fuente" @input="$v.editForm.source.$touch()" />

          </b-form-group>
        </b-col>

        <b-col xl="6" sm="12">
          <b-form-group label="Fecha" label-for="fecha">
            <b-form-datepicker locale="es" id="date_ini" placeholder="Fecha" v-model="editForm.date"
              :state="dateErrors.length > 0 ? false : null" @input="$v.editForm.date.$touch()" />

            <small class="text-danger">{{ dateErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col vs-type="flex" xl="6" sm="12">
          <b-form-group label="ID Video Youtube" label-for="video_youtube_id">
            <b-form-input id="video_youtube_id" v-model="editForm.video_youtube_id" name="video_youtube_id"
              placeholder="Ej: ASCGSRX12" />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay :show="overlayForm" opacity="0.5" spinner-small spinner-variant="primary"
                class="d-inline-block" @hidden="_onHidden()">
                <b-button block ref="button" :disabled="overlayForm" variant="primary" @click.prevent="_updateNews()">
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle">
                    Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required, numeric, email, maxLength } from 'vuelidate/lib/validators'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'NewsFormEdit',
  props: ['newsingle'],
  components: {
  },
  mounted() {
  },
  data() {
    return {
      overlayForm: false,
      editForm: {
        id: '',

        title: '',
        body: '',
        date: '',
        user_id: '',
        status: '',

        subtitle: '',
        source: '',
        body_footer: '',

        media_logo: { full_url: '' },
        media_coverpage: { full_url: '' },
        logo: '',
        cover_page: [],
        tagsNews: [],
        projectsNews: [],
        video_youtube_id: '',
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          'heading', '|', 'bold', 'italic', '|', "undo", "redo", 'link', 'bulletedList', 'numberedList', 'blockQuote',
          'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',
        ],
        height: "500px"
      }
    }
  },
  validations: {
    editForm: {
      title: {
        required,
      },

      body: {
        required,
      },
      date: {
        required,
      },
    },
  },
  beforeMount() {
    this.fetchTagsNews()

    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    if (Array.isArray(this.newsingle.medias)) {
      this.newsingle.medias.forEach(element => {
        if (element) {
          if (element.mediatype.id == 2) {
            element.full_url = element.full_url + "?" + time;
            this.editForm.media_coverpage = element;
          }
          if (element.mediatype.id == 1) {
            element.full_url = element.full_url + "?" + time;
            this.editForm.media_logo = element;
          }
        }
      });
    }

    this._prefill()
  },
  computed: {
    ...mapGetters('profile', ['projects']),
    ...mapGetters('tagsNews', ['dataTagsNews']),
    titleErrors() {
      const errors = []
      if (!this.$v.editForm.title.$dirty) return errors
      !this.$v.editForm.title.required && errors.push('El titulo es obligatorio')
      return errors
    },
    subtitleErrors() {
      const errors = []
      if (!this.$v.editForm.subtitle.$dirty) return errors
      !this.$v.editForm.subtitle.required && errors.push('El subtitulo es obligatorio')
      return errors
    },
    bodyErrors() {
      const errors = []
      if (!this.$v.editForm.body.$dirty) return errors
      !this.$v.editForm.body.required && errors.push('El cuerpo es obligatorio')
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.$v.editForm.date.$dirty) return errors
      !this.$v.editForm.date.required && errors.push('La fecha es obligatoria')
      return errors
    },

  },
  methods: {
    ...mapActions('news', ['updateNews']),
    ...mapActions('tagsNews', ['fetchTagsNews']),
    async _updateNews() {
      this.$v.editForm.$touch()
      if (!this.$v.editForm.$invalid) {
        this.overlayForm = true
        await this.updateNews(this.editForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Noticia modificada con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })

            this.$router.push({ name: 'news' })
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar la noticia',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
      }
    },
    _prefill() {
      this.editForm.id = this.newsingle.id;
      this.editForm.title = this.newsingle.title;
      this.editForm.body = this.newsingle.body;
      this.editForm.date = this.newsingle.date;
      this.editForm.user_id = this.newsingle.user_id;
      this.editForm.status = this.newsingle.status;
      this.editForm.subtitle = this.newsingle.subtitle;
      this.editForm.source = this.newsingle.source;
      this.editForm.body_footer = this.newsingle.body_footer;
      this.editForm.tagsNews = this.newsingle.tagsNews.map(e => e.id);
      this.editForm.projectsNews = this.newsingle.projects.map(e => e.id);
      this.editForm.video_youtube_id = this.newsingle.video_youtube_id;
    },
    _onHidden() {
      if (this.$refs.button != null) {
        this.$refs.button.focus()
      }
    },
    _previewLogo(event) {
      if (event.target.files[0] != null) {
        if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
          this.editForm.media_logo.full_url = URL.createObjectURL(event.target.files[0])
          this.editForm.logo = event.target.files[0]
        } else {
          document.getElementById("logo").value = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Formato de archivo incorrecto',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      } else {
      }
    },
    _previewPortada(event) {
      if (event.target.files[0] != null) {
        if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/png') {
          this.editForm.media_coverpage.full_url = URL.createObjectURL(event.target.files[0])
          this.editForm.cover_page = event.target.files[0]
        } else {
          document.getElementById("cover_page").value = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Formato de archivo incorrecto',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      } else {
      }
    },
  }
}
</script>
<style>
.ckeditor {
  height: 400px;
  width: 100%;
}

.ck-content {
  height: 350px;
}

.margin-bottom-20 {
  margin-bottom: 30px;
}

.divInfo {
  border-radius: 10px
}
</style>